import axios, { all } from "axios";
import { useEffect, useRef, useState } from "react";
import Overview from "./Overview";
import Application from "./Settings/Application";
import Orientation from "./Settings/Orientation";
import Material from "./Settings/Material";
import Color from "./Settings/Color";
import Polishing from "./Settings/Polishing";
import Coating from "./Settings/Coating";
import Sterile from "./Settings/Sterile";
import IntendedUse from "./Settings/IntendedUse";
import ClinicalUse from "./Settings/ClinicalUse";
import RetrieveProductSettings from "./RetrieveProductSettings";
import { useGlobalState } from "../GlobalContext";
import { Link } from "react-router-dom";
import { excludeSettings } from "./FilterSettings";

export default function ProductSettings(props) {
    const [productSettingsExclusions, setProductSettingsExclusions] = useState([]);
    const [originalProductSettings, setOriginalProductSettings] = useState([]);
    const [loadingProductSettings, setLoadingProductSettings] = useState(true);
    // Save the choices made by the user
    const [chosenConfig, setChosenConfig] = useState({
        application: null,
        material: null,
        color: null,
        polish: null,
        coating: null,
        orientation: null,
        sterile: null,
        intendedUse: null,
        clinicalUse: null,
        custom: null,
        case: null,
        practitioner: null,
    });
    const [currentScreen, setCurrentScreen] = useState("overview");
    const {
        productSettings,
        setProductSettings,
        setMandatoryProductSettings,
        productSettingExplanations,
        setProductSettingExplanations,
    } = useGlobalState();
    const clearOnNextIteration = useRef(false);

    useEffect(() => {
        loadProductSettings();
        loadMaterialExplanations();
        loadProductSettingsExclusions();
    }, []);

    useEffect(() => {
        if (clearOnNextIteration.current) {
            clearSettingsThatAreNotAllowed();
            clearOnNextIteration.current = false;
        }
    }, [chosenConfig]);

    useEffect(() => {
        if (props.saveFooter) {
            saveProductSettings();
            props.setSaveFooter(false);
        }
    }, [props.saveFooter]);

    useEffect(() => {
        if (originalProductSettings.length === 0 && productSettings.length > 0) {
            setOriginalProductSettings(productSettings);
        }

        if (typeof productSettingsExclusions !== "undefined") {
            // Hardcode the medical exclusion since this needs to be set for every user.
            productSettingsExclusions.push({
                application: "Medical",
                material: null,
                color: null,
                polish: null,
                coating: null,
                orientation: null,
                sterile: true,
                intendedUse: null,
                clinicalUse: null,
                custom: null,
                case: null,
                practitioner: null,
                isAllowed: false,
            });

            // Remove PA11
            productSettingsExclusions.push({
                application: null,
                material: "Oceanz PA11 Blue",
                color: null,
                polish: null,
                coating: null,
                orientation: null,
                sterile: null,
                intendedUse: null,
                clinicalUse: null,
                custom: null,
                case: null,
                practitioner: null,
                isAllowed: false,
            });
        }

        const prodSettings = originalProductSettings.length > 0 ? originalProductSettings : productSettings;
        if (typeof productSettingsExclusions !== "undefined" && productSettingsExclusions.length > 0) {
            // Filter out & whitelist certain combinations
            const allowedCombinations = productSettingsExclusions.filter((setting) => setting.isAllowed == true);
            const notAllowedCombinations = productSettingsExclusions.filter((setting) => setting.isAllowed == false);
            const filtered = excludeSettings(notAllowedCombinations, allowedCombinations, prodSettings);
            setProductSettings(filtered);
        }
    }, [productSettingsExclusions]);

    function loadProductSettingsExclusions() {
        if (
            typeof productSettingsExclusions === "undefined" ||
            productSettingsExclusions === null ||
            productSettingsExclusions.length === 0
        ) {
            axios
                .get("/api/product/exclusions?user_id=" + props.loggedInUser.id)
                .then((response) => {
                    let exclusions = response.data;
                    setProductSettingsExclusions(exclusions);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    function loadMaterialExplanations() {
        if (
            typeof productSettingExplanations === "undefined" ||
            productSettingExplanations === null ||
            productSettingExplanations.length === 0
        ) {
            axios
                .get("/api/product-explanations")
                .then((response) => {
                    let materialExplanations = response.data.explanations;
                    setProductSettingExplanations(materialExplanations);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    useEffect(() => {
        // check if models are selected
        let selectedModels = props.modelsData[0].data.filter(function (element) {
            return element.checked === true;
        });
        if (selectedModels.length > 0) {
            resetModelSettingsIfCurrentSettingsAreInvalid();
        }
    }, [props.modelsData]);

    // Get product settings
    function loadProductSettings() {
        RetrieveProductSettings({ setMandatoryProductSettings, setProductSettings }).then((response) => {
            const selectedModels = props.modelsData[0].data.filter(function (element) {
                return element.checked === true;
            });

            if (
                selectedModels.length > 0 &&
                typeof selectedModels[0].settings !== "undefined" &&
                selectedModels[0].settings !== null
            ) {
                let settingsSterile = false;
                try {
                    if (selectedModels[0]?.settings?.sterile === "No") {
                        settingsSterile = false;
                    } else if (selectedModels[0]?.settings?.sterile === "Yes") {
                        settingsSterile = true;
                    } else {
                        settingsSterile = JSON.parse(selectedModels[0].settings.sterile);
                    }
                } catch (e) {
                    // Check if the value is undefined or not
                    console.log(selectedModels[0]?.settings?.sterile);
                    if (selectedModels[0]?.settings?.sterile === "No") {
                        settingsSterile = false;
                    } else if (selectedModels[0]?.settings?.sterile === "Yes") {
                        settingsSterile = true;
                    }
                }

                if (settingsSterile === null) {
                    settingsSterile = false;
                }

                // Check if the value is string or not
                let settingsIntendedUse, settingsClinicalUse, settingsCustom;
                settingsIntendedUse =
                    typeof selectedModels[0].settings.intendedUse !== "undefined"
                        ? selectedModels[0].settings.intendedUse
                        : null;
                if (typeof settingsIntendedUse === "string") {
                    if (settingsIntendedUse === "No") {
                        settingsIntendedUse = false;
                    } else if (settingsIntendedUse === "Yes") {
                        settingsIntendedUse = true;
                    }
                }

                settingsClinicalUse =
                    typeof selectedModels[0].settings.clinicalUse !== "undefined"
                        ? selectedModels[0].settings.clinicalUse
                        : null;
                if (typeof settingsClinicalUse === "string") {
                    if (settingsClinicalUse === "No") {
                        settingsClinicalUse = false;
                    } else if (settingsClinicalUse === "Yes") {
                        settingsClinicalUse = true;
                    }
                } else if (settingsClinicalUse === null) {
                    settingsClinicalUse = false;
                }

                settingsCustom =
                    typeof selectedModels[0].settings.custom !== "undefined" ? selectedModels[0].settings.custom : null;
                if (typeof settingsCustom === "string") {
                    if (settingsCustom === "No") {
                        settingsCustom = false;
                    } else if (settingsCustom === "Yes") {
                        settingsCustom = true;
                    }
                }

                let settingCase =
                    typeof selectedModels[0].settings.case !== "undefined" ? selectedModels[0].settings.case : null;
                if (settingCase === null && (settingsCustom === true || settingsCustom === "Yes")) {
                    settingCase = "-";
                }

                let settingsPractitioner =
                    typeof selectedModels[0].settings.practitioner !== "undefined"
                        ? selectedModels[0].settings.practitioner
                        : null;
                if (settingsPractitioner === null && (settingsCustom === true || settingsCustom === "Yes")) {
                    settingsPractitioner = "-";
                }
                setLoadingProductSettings(false);
                setChosenConfig({
                    application: selectedModels[0].settings.application,
                    material: selectedModels[0].settings.material,
                    color: selectedModels[0].settings.color,
                    polish: selectedModels[0].settings.polish,
                    coating: selectedModels[0].settings.coating,
                    orientation: selectedModels[0].settings.orientation,
                    sterile: settingsSterile,
                    intendedUse: settingsIntendedUse,
                    clinicalUse: settingsClinicalUse,
                    custom: settingsCustom,
                    case: settingCase,
                    practitioner: settingsPractitioner,
                });
            }
        });
    }

    function selectFirstOptionForEachSetting() {
        // Select first (allowed) option for each setting
        let settingsOrder = [
            "sterile",
            "intendedUse",
            "clinicalUse",
            "custom",
            "case",
            "practitioner",
            "material",
            "color",
            "polish",
            "coating",
            "orientation",
        ];

        let currentConfig = {
            application: chosenConfig.application,
            material: null,
            color: null,
            polish: null,
            coating: null,
            orientation: null,
            sterile: null,
            intendedUse: null,
            clinicalUse: null,
            custom: null,
            case: null,
            practitioner: null,
        };

        let chosen = currentConfig;
        settingsOrder.forEach((element) => {
            let allowed = allowedValues(element, chosen, false);
            if (allowed.length === 0) {
                chosen[element] = null;
            } else {
                if (element === "sterile" && currentConfig.application.toLowerCase() !== "medical") {
                    chosen[element] = null;
                } else if (
                    element === "intendedUse" &&
                    currentConfig.application.toLowerCase() !== "medical" &&
                    currentConfig.application.toLowerCase() !== "dental"
                ) {
                    chosen[element] = null;
                } else {
                    switch (typeof allowed[0]) {
                        case "boolean":
                            chosen[element] = allowed[0];
                            break;
                        case "string":
                            chosen[element] = allowed[0];
                            break;
                        case "object":
                            if (element === "color") {
                                chosen[element] = allowed[0].name;
                            }
                            break;
                        default:
                            if (element === "material") {
                                let nameFound = false;
                                if (allowed && Object.keys(allowed).length > 0) {
                                    const firstKey = Object.keys(allowed)[0];
                                    if (allowed[firstKey] && allowed[firstKey].length > 0) {
                                        const firstAllowedItem = allowed[firstKey][0];
                                        if (firstAllowedItem && firstAllowedItem.name) {
                                            chosen[element] = firstAllowedItem.name;
                                            nameFound = true;
                                        }
                                    }
                                }

                                if (!nameFound) {
                                    chosen[element] = null;
                                }
                            } else {
                                chosen[element] = null;
                            }
                            break;
                    }
                }

                // Coating is temporarily disabled
                if (element === "coating") {
                    chosen[element] = null;
                }
            }
        });

        setChosenConfig((prev) => {
            return { ...prev, ...chosen };
        });

        return new Promise((resolve) => {
            resolve(chosen);
        });
    }

    function clearSettingsThatAreNotAllowed(clearOnNextIter = false, lastEditedSetting = null) {
        if (clearOnNextIter) {
            clearOnNextIteration.current = true;
            return;
        }

        // application is excluded because you can always change it (root of all settings)
        let settingsOrder = [
            "sterile",
            "intendedUse",
            "clinicalUse",
            "custom",
            "case",
            "practitioner",
            "material",
            "color",
            "polish",
            "orientation",
        ];
        //NOTE: Coating is temporarily removed from settingsOrder

        // Get last edited setting
        if (lastEditedSetting !== null && lastEditedSetting === "coating") {
            // put the setting on top of the order
            settingsOrder = settingsOrder.filter((setting) => setting !== lastEditedSetting);
            settingsOrder.unshift(lastEditedSetting);
        }

        let currentConfig = {
            application: chosenConfig.application,
            material: null,
            color: null,
            polish: null,
            coating: null,
            orientation: null,
            sterile: null,
            intendedUse: null,
            clinicalUse: null,
            custom: null,
            case: null,
            practitioner: null,
        };
        let chosen = currentConfig;
        let setPolishingToFirstValue = false; // set polishing to the first allowed value if coating is changed
        let setCoatingToFirstValue = false; // set coating to the first allowed value if polishing is changed
        settingsOrder.forEach((element) => {
            let allowed = allowedValues(element, chosen, false);

            // if (element === "coating" && lastEditedSetting === "coating") {
            //     allowed = allowedValues(element, chosen, true);
            // }

            if (
                (element === "clinicalUse" ||
                    element === "custom" ||
                    element === "case" ||
                    element === "intendedUse" ||
                    element === "practitioner") &&
                chosenConfig.application !== null &&
                chosenConfig.application.toLowerCase().trim() !== "medical"
            ) {
                chosen[element] = null;
            }
            if (element === "case" || element === "practitioner") {
                chosen[element] = chosenConfig[element];
            } else {
                if (allowed.length === 0) {
                    chosen[element] = null;
                } else {
                    if (element === "sterile") {
                        if (chosenConfig[element] === "true") {
                            chosen[element] = true;
                        } else if (chosenConfig[element] === "false") {
                            chosen[element] = false;
                        } else if (typeof chosenConfig[element] === "boolean") {
                            chosen[element] = chosenConfig[element];
                        } else {
                            chosen[element] = null;
                        }
                    } else {
                        let found = false;
                        if (element === "material") {
                            let sls = Object.keys(allowed[1]).map((key) => allowed[1][key]);
                            let result = sls;
                            if (typeof allowed[2] !== "undefined") {
                                let slm = Object.keys(allowed[2]).map((key) => allowed[2][key]);
                                let merged = sls.concat(slm);
                                result = merged;
                            }

                            if (typeof allowed[3] !== "undefined") {
                                let sla = Object.keys(allowed[3]).map((key) => allowed[3][key]);
                                let merged = result.concat(sla);
                                result = merged;
                            }
                            allowed = result;
                        }

                        allowed.forEach((allowedElement) => {
                            let name = "";
                            if (Object.hasOwn(allowedElement, "name")) {
                                name = allowedElement.name;
                            } else {
                                name = allowedElement;
                            }

                            if (name === chosenConfig[element]) {
                                found = true;
                            }
                        });

                        if (found) {
                            chosen[element] = chosenConfig[element];
                        } else {
                            // Check if there is a allowed value to choose instead of leaving it empty
                            if (allowed.length > 0) {
                                switch (typeof allowed[0]) {
                                    case "boolean":
                                        chosen[element] = allowed[0];
                                        break;
                                    case "string":
                                        chosen[element] = allowed[0];
                                        break;
                                    case "object":
                                        if (element === "color") {
                                            chosen[element] = allowed[0].name;
                                        }
                                        break;
                                    default:
                                        chosen[element] = null;
                                        break;
                                }
                            } else {
                                chosen[element] = null;
                            }
                        }
                    }
                }
            }
            if (element === "coating" && lastEditedSetting === "coating" && chosen["polish"] === null) {
                setPolishingToFirstValue = true;
            } else if (element === "polish" && lastEditedSetting === "polish" && chosen["coating"] === null) {
                // Coating is temporarily disabled
                //setCoatingToFirstValue = true;
            }

            // Coating is temporarily disabled
            if (element === "coating") {
                chosen[element] = null;
            }
        });
        if (setPolishingToFirstValue) {
            let allowed = allowedValues("polish", chosen, false);
            chosen["polish"] = allowed[0];
        }
        if (setCoatingToFirstValue) {
            let allowed = allowedValues("coating", chosen, false);
            chosen["coating"] = allowed[0];
        }

        chosen["coating"] = "No";

        setChosenConfig((prev) => {
            return { ...prev, ...chosen };
        });
    }

    // Returns the user to the next possible setting (skip settings that cant be filled)
    function getNextPossibleSetting(currentSetting) {
        let settingsOrder = [
            "sterile",
            "intendedUse",
            "clinicalUse",
            "custom",
            "case",
            "practitioner",
            "material",
            "color",
            "polish",
            "coating",
            "orientation",
        ];
        let result = null;
        settingsOrder.forEach((element, index) => {
            if (element === currentSetting && index < settingsOrder.length) {
                result = settingsOrder.slice(index + 1, settingsOrder.length);
            }
        });

        // Loop all the settings that still need to be filled in and check if they are allowed
        let foundNextSetting = false;
        let output = false;
        result.forEach((element) => {
            // Make exception rules for medical
            if (
                chosenConfig.application !== "Medical" &&
                chosenConfig.application !== "Dental" &&
                (element === "intendedUse" || element === "sterile")
            ) {
                return;
            } else {
                if (chosenConfig.application === "Dental" && element === "sterile") {
                    return;
                }
                let allowed = allowedValues(element, chosenConfig);
                if (foundNextSetting === false && Object.keys(allowed).length > 0) {
                    output = element;
                    foundNextSetting = true;
                }
            }
        });
        return output;
    }

    // Get the chosen settings and display them in the overview
    function getOverview(productSettings) {
        if (productSettings === null || typeof productSettings === "undefined") {
            return;
        }

        let headers = ["", "", "", "", "", ""];
        const types = ["application", "material", "color", "polish", "coating", "orientation"];
        if (chosenConfig.application === "Medical") {
            types.splice(1, 0, "clinicalUse");
            types.splice(1, 0, "intendedUse");
            types.splice(1, 0, "sterile");
        } else if (chosenConfig.application === "Dental") {
            types.splice(1, 0, "intendedUse");
        }
        if (props.textData !== false) {
            headers = [
                props.textData["sections/application"].header,
                props.textData["sections/material"].header,
                props.textData["sections/color"].header,
                props.textData["sections/polishing"].header,
                props.textData["sections/coating"].header,
                props.textData["sections/orientation"].header,
            ];
            if (chosenConfig.application === "Medical") {
                headers.splice(1, 0, props.textData["sections/clinicalUse"].header);
                headers.splice(1, 0, props.textData["sections/intendedUse"].header);
                headers.splice(1, 0, props.textData["sections/label"]["sterile"].header);
            } else if (chosenConfig.application === "Dental") {
                headers.splice(1, 0, props.textData["sections/intendedUse"].header);
            }
        }
        // Loop through the settings and return a Setting component for each setting.
        if (Object.keys(productSettings).length > 0) {
            return (
                <div>
                    <div className="product-settings-btns">
                        {headers.map((title, index) => {
                            if (
                                chosenConfig.application === "Medical" &&
                                (types[index] === "sterile" || types[index] === "clinicalUse")
                            ) {
                                return (
                                    <Overview
                                        key={index}
                                        title={title}
                                        config={chosenConfig}
                                        type={types[index]}
                                        setCurrentScreen={setCurrentScreen}
                                        textData={props.textData["sections/label"]["sterile"]}
                                        productSettingExplanations={productSettingExplanations}
                                        disabled={
                                            types[index] === "application"
                                                ? false
                                                : allowedValues(types[index], chosenConfig, false).length === 0
                                        }
                                    />
                                );
                            } else {
                                return (
                                    <Overview
                                        key={index}
                                        title={title}
                                        config={chosenConfig}
                                        type={types[index]}
                                        setCurrentScreen={setCurrentScreen}
                                        productSettingExplanations={productSettingExplanations}
                                        disabled={
                                            types[index] === "application"
                                                ? false
                                                : allowedValues(types[index], chosenConfig, false).length === 0
                                        }
                                    />
                                );
                            }
                        }, this)}
                    </div>
                    <button
                        className="btn btn-save"
                        onClick={() => {
                            saveProductSettings();
                        }}
                    >
                        Opslaan
                    </button>
                </div>
            );
        }
    }

    function getApplicationSettings() {
        return (
            <Application
                infoTextData={props.textData["sections/infoButton"]}
                textData={props.textData["sections/application"]}
                config={chosenConfig}
                updateSetting={updateSetting}
                applications={getApplications()}
                goNext={goNext}
                getNextPossibleSetting={getNextPossibleSetting}
                clearSettingsThatAreNotAllowed={clearSettingsThatAreNotAllowed}
                productSettingExplanations={productSettingExplanations}
            />
        );
    }

    function getMaterialSettings() {
        return (
            <Material
                infoTextData={props.textData["sections/infoButton"]}
                textData={props.textData["sections/material"]}
                config={chosenConfig}
                updateSetting={updateSetting}
                materials={allowedValues("material", chosenConfig)}
                goNext={goNext}
                getNextPossibleSetting={getNextPossibleSetting}
                clearSettingsThatAreNotAllowed={clearSettingsThatAreNotAllowed}
                productSettingExplanations={productSettingExplanations}
            />
        );
    }

    function getCoatingSettings() {
        return (
            <Coating
                infoTextData={props.textData["sections/infoButton"]}
                textData={props.textData["sections/coating"]}
                config={chosenConfig}
                updateSetting={updateSetting}
                coatings={allowedValues("coating", chosenConfig)}
                goNext={goNext}
                getNextPossibleSetting={getNextPossibleSetting}
                clearSettingsThatAreNotAllowed={clearSettingsThatAreNotAllowed}
                productSettingExplanations={productSettingExplanations}
            />
        );
    }

    function getColorSettings() {
        return (
            <Color
                infoTextData={props.textData["sections/infoButton"]}
                textData={props.textData["sections/color"]}
                config={chosenConfig}
                updateSetting={updateSetting}
                colors={allowedValues("color", chosenConfig)}
                goNext={goNext}
                getNextPossibleSetting={getNextPossibleSetting}
                clearSettingsThatAreNotAllowed={clearSettingsThatAreNotAllowed}
                productSettingExplanations={productSettingExplanations}
            />
        );
    }

    function getPolishingSettings() {
        return (
            <Polishing
                infoTextData={props.textData["sections/infoButton"]}
                textData={props.textData["sections/polishing"]}
                config={chosenConfig}
                updateSetting={updateSetting}
                polishes={allowedValues("polish", chosenConfig)}
                goNext={goNext}
                getNextPossibleSetting={getNextPossibleSetting}
                clearSettingsThatAreNotAllowed={clearSettingsThatAreNotAllowed}
                productSettingExplanations={productSettingExplanations}
            />
        );
    }

    function getOrientationSettings() {
        return (
            <Orientation
                infoTextData={props.textData["sections/infoButton"]}
                textData={props.textData["sections/orientation"]}
                config={chosenConfig}
                updateSetting={updateSetting}
                orientations={allowedValues("orientation", chosenConfig)}
                goNext={goNext}
                getNextPossibleSetting={getNextPossibleSetting}
                clearSettingsThatAreNotAllowed={clearSettingsThatAreNotAllowed}
                productSettingExplanations={productSettingExplanations}
            />
        );
    }

    function getSterileSettings() {
        return (
            <Sterile
                infoTextData={props.textData["sections/infoButton"]}
                textData={props.textData["sections/label"]["sterile"]}
                config={chosenConfig}
                updateSetting={updateSetting}
                sterileOptions={allowedValues("sterile", chosenConfig)}
                goNext={goNext}
                getNextPossibleSetting={getNextPossibleSetting}
                clearSettingsThatAreNotAllowed={clearSettingsThatAreNotAllowed}
                productSettingExplanations={productSettingExplanations}
            />
        );
    }

    function getIntendedUseSettings() {
        return (
            <IntendedUse
                infoTextData={props.textData["sections/infoButton"]}
                textData={props.textData["sections/intendedUse"]}
                config={chosenConfig}
                updateSetting={updateSetting}
                intendedUseOptions={allowedValues("intendedUse", chosenConfig)}
                goNext={goNext}
                getNextPossibleSetting={getNextPossibleSetting}
                clearSettingsThatAreNotAllowed={clearSettingsThatAreNotAllowed}
                productSettingExplanations={productSettingExplanations}
            />
        );
    }

    function getClinicalUseSettings() {
        return (
            <ClinicalUse
                infoTextData={props.textData["sections/infoButton"]}
                textData={props.textData["sections/clinicalUse"]}
                config={chosenConfig}
                updateSetting={updateSetting}
                clinicalUseOptions={allowedValues("clinicalUse", chosenConfig)}
                goNext={goNext}
                getNextPossibleSetting={getNextPossibleSetting}
                clearSettingsThatAreNotAllowed={clearSettingsThatAreNotAllowed}
                productSettingExplanations={productSettingExplanations}
            />
        );
    }

    function getApplications() {
        return productSettings.map((element) => element.name);
    }

    // Removes duplicates from an array of objects based on the given property
    function removeDuplicates(myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
            return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }

    function groupByCategoryId(array) {
        return array.reduce((result, obj) => {
            const categoryId = obj.category_id;
            if (!result[categoryId]) {
                result[categoryId] = [];
            }
            result[categoryId].push(obj);
            return result;
        }, {});
    }

    // Get all allowed values for a given type (application, color, etc.)
    function allowedValues(setting, chosenConfig, excludeGivenSetting = true) {
        let result = [];
        let allowedChoices = [];
        if (excludeGivenSetting) {
            allowedChoices = getAllowedChoices(setting, chosenConfig);
        } else {
            allowedChoices = getAllowedChoices(null, chosenConfig);
        }

        // ---------------------------------------------
        allowedChoices.forEach((element) => {
            const unique = element.settings.map((item) => item[setting]);
            // add to array
            result = result.concat(unique);
            // remove duplicates
            result = [...new Set(result)];
            // remove nulls from list
            result = result.filter(function (el) {
                return el != null;
            });
        });

        if (setting === "color" || setting === "material") {
            if (setting === "material") {
                result = removeDuplicates(result, "name");

                // Divide the materials into categories
                result = groupByCategoryId(result);
                return result;
            } else {
                result = removeDuplicates(result, "name");
            }
            result = result.filter(function (el) {
                return el.name != null;
            });

            return result;
        }
        return result;
    }

    // Check if the current choices are allowed together
    // Excluding is the setting that is being changed so it should be included in some cases
    function getAllowedChoices(excluding = null, chosenConfig) {
        let allowedChoices = productSettings;
        // Check if application has been chosen
        if (chosenConfig.application === null) {
            return allowedChoices;
        }

        // filter through the application types untill we only have the chosen one
        let filteredData = allowedChoices.filter(function (item) {
            return item.name === chosenConfig.application;
        });

        // Check if we can filter further
        if (filteredData.length === 0) {
            return [];
        }

        let settingsOrder = [
            "sterile",
            "intendedUse",
            "clinicalUse",
            "custom",
            "material",
            "color",
            "polish",
            "coating",
            "orientation",
        ];

        if (excluding !== null && excluding === "coating") {
            // put the excluded setting on top of the order
            settingsOrder = settingsOrder.filter((setting) => setting !== excluding);
            settingsOrder.unshift(excluding);
        }

        // filter through the settings
        let result = [];
        filteredData.forEach((item) => {
            let res = {};
            res.name = item.name;
            res.settings = item.settings.filter(function (element) {
                let checks = [];
                settingsOrder.forEach((setting, index) => {
                    let name = "";
                    if (
                        typeof element[setting] !== "undefined" &&
                        element[setting] !== null &&
                        Object.hasOwn(element[setting], "name")
                    ) {
                        name = element[setting].name;
                    } else {
                        name = element[setting];
                    }

                    if (name === chosenConfig[setting] || chosenConfig[setting] == null || excluding === setting) {
                        checks.push({ check: true, element: element[setting], name: name });
                    } else {
                        if (excluding !== null) {
                            let excludingIndex = settingsOrder.indexOf(excluding);
                            if (index < excludingIndex) {
                                checks.push({ check: false, element: element[setting], name: name });
                            }
                        } else if (
                            setting === "intendedUse" &&
                            chosenConfig["application"].toLowerCase() === "medical"
                        ) {
                            checks.push({ check: true, element: element[setting], name: name });
                        } else {
                            checks.push({ check: false, element: element[setting], name: name });
                        }
                    }
                });

                // check if all checks are true
                let allTrue = checks.every((element) => element.check === true);
                return allTrue;
            });
            result.push(res);
        });
        return result;
    }

    const resetModelSettingsIfCurrentSettingsAreInvalid = () => {
        // go though all selected models and check if the current settings are allowed
        let selectedModels = props.modelsData[0].data.filter(function (element) {
            return element.checked === true;
        });

        if (selectedModels.length === 0) {
            return;
        }

        selectedModels.forEach((model) => {
            if (getAllowedChoices(null, model.settings).length === 0) {
                // reset the settings to default
                model.settings = {
                    application: "Retail & Industrial",
                    material: "Oceanz PA12",
                    color: "No colouring",
                    polish: "No",
                    coating: "No",
                    orientation: "unlocked",
                    sterile: null,
                    intendedUse: null,
                    clinicalUse: null,
                    custom: null,
                    case: null,
                    practitioner: null,
                };
            }
        });
    };

    // Update the config with a new value (e.g. colour: red)
    function updateSetting(type, value) {
        let oldApplication = chosenConfig.application;
        if (value === chosenConfig[type] && type !== "sterile") return false; // same value, no need to update

        // Check if multiple are selected
        let selectedModels = props.modelsData[0].data.filter(function (element) {
            return element.checked === true;
        });

        if (type === "clinicalForm" && value !== null) {
            let config = chosenConfig;
            config["clinicalUse"] = value.clinicalUse;
            config["case"] = value.case;
            config["practitioner"] = value.practitioner;
            config["custom"] = value.custom;

            props.updateModelSettings(config);

            setChosenConfig(config);
            return;
        }

        let config = chosenConfig;
        config[type] = value;

        if (type === "application") {
            // If food contact, set material to PA12 Blue

            if (
                chosenConfig.application !== null &&
                value !== "medical" &&
                value !== "dental" &&
                oldApplication !== null &&
                (oldApplication.toLowerCase() === "medical" || oldApplication.toLowerCase() === "dental")
            ) {
                config["intendedUse"] = null;
                config["sterile"] = null;
            }

            selectFirstOptionForEachSetting(value).then((newConfig) => {
                if (selectedModels.length < 2) {
                    props.updatePricesOnNextIteration();
                }

                if (value === "Food Contact") {
                    newConfig["material"] = "Oceanz PA12 Blue";
                }

                props.updateModelSettings({ ...newConfig, application: value });
                // if (chosenConfig.color !== "No colouring") {
                //     getColorInRgb("No colouring").then((rgb) => {
                //         props.updateSelectedModelsColor(rgb);
                //     });
                // }
                setChosenConfig({ ...newConfig, application: value });
                if (selectedModels.length > 2) {
                    props.setUpdatingMultipleModels(true);
                }
            });
            return true;
        }

        if (type === "sterile" && value === "false" && config["intendedUse"] === "Max 24h surgically invasive use") {
            config["intendedUse"] = "Permanent use on intact skin";
        }

        setChosenConfig(config);
        props.updateModelSettings(config); // callback to home.jsx, to update all selected models

        // if (type === "color" && value !== null) {
        //     getColorInRgb(value).then((rgb) => {
        //         props.updateSelectedModelsColor(rgb);
        //     });
        // }

        if (type === "orientation" && value !== null) {
            let orientationLocked = false;
            if (value === "locked") {
                orientationLocked = true;
            }
            //props.updateModelOrientation(orientationLocked); <-- obsolete now
        }

        if (type === "sterile" && value === null) {
            if (config["application"] === "Medical") {
                config["sterile"] = false;
                setChosenConfig((prev) => ({ ...prev, sterile: false }));
            }
        }

        if (selectedModels.length > 1) {
            props.setUpdatingMultipleModels(true);
        } else {
            props.updatePricesOnNextIteration();
        }

        return true;
    }

    function goNext(screen) {
        return setCurrentScreen(screen);
    }

    function render() {
        const disableProductSettings = props.disableProductSettings;
        const settingsComponent = () => {
            switch (currentScreen) {
                case "overview":
                    return getOverview(productSettings);
                case "application":
                    return getApplicationSettings();
                case "material":
                    return getMaterialSettings();
                case "color":
                    return getColorSettings();
                case "polish":
                    return getPolishingSettings();
                case "coating":
                    return getCoatingSettings();
                case "orientation":
                    return getOrientationSettings();
                case "sterile":
                    return getSterileSettings();
                case "intendedUse":
                    return getIntendedUseSettings();
                case "clinicalUse":
                    return getClinicalUseSettings();
                default:
                    return getOverview(productSettings);
            }
        };

        // Wrap the settings component to disable it based on disableProductSettings
        return (
            <>
                {loadingProductSettings && <span className="loading"></span>}
                {!loadingProductSettings && (
                    <div
                        style={{
                            pointerEvents: disableProductSettings ? "none" : "auto",
                            opacity: disableProductSettings ? 0.5 : 1,
                        }}
                    >
                        {settingsComponent()}
                    </div>
                )}
            </>
        );
    }

    function saveProductSettings() {
        // get by id delete-all-header-btn
        let btn = document.getElementsByClassName("delete-all-header-btn")[0];
        if (btn !== null) {
            btn.style.opacity = "1";
        }
        props.saveProductSettings(chosenConfig);
    }

    return (
        <div className={"sidebar-container"}>
            <div className="sidebar-menu">
                {!!props.textData && (
                    <div className="product-settings">
                        <div className="sidebar-logo">
                            <Link to={"/"}>
                                <img src={"/Oceanz_logo_nieuw_FC.png"} className="logo" alt="logo" />
                            </Link>
                        </div>
                        <div>{render()}</div>
                    </div>
                )}
                {!props.textData && <span className="loading"></span>}
            </div>
        </div>
    );
}
